<template>
  <div>
    <main-header v-if="user.authenticated"></main-header>

    <div class="ui container">
      <transition mode="out-in">
        <router-view v-if="loaded" />
      </transition>
    </div>

    <main-footer v-if="user.authenticated"></main-footer>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import '@../../../templates/brand/css/app.css'
import MainHeader from '@/components/MainHeader.vue'
import MainFooter from '@/components/MainFooter.vue'
import auth from '@/util/auth'

export default {
  name: 'AppRoot',
  created () {
    console.info(' :: created APP')
    // document.title = "Honda"
    auth.checkAuth({ $http: this.$http, $route: this.$route })
      .then(res => {
        if (res.success) {
          this.$store.commit('AUTHENTICATED_USER', res.user)
        } else {
          this.$store.commit('LOGOUT')
        }
        this.loaded = true
        return res
      })
      .catch(() => {
        // console.error(' :: auth error in App, redirect to login', this.$route, err)
        this.loaded = true
        if (this.$route.path === '/') {
          // only check first time and only when $route is resolved
          const init = this.$watch('$route', () => {
            auth.checkAuth({ $http: this.$http, $route: this.$route })
              .then(res => {
                if (res.success) {
                  this.$store.commit('AUTHENTICATED_USER', res.user)
                } else {
                  this.$store.commit('LOGOUT')
                }
                this.loaded = true
                return res
              })
              .catch(() => {
                this.$router.push('/login')
              })
            init()
          })
        }
      }) // then get permissions for user and add to store
    const unwatch = this.$watch('user', () => {
      if (this.user.authenticated) {
        this.getHomeConfig()
        if (this.user.profile.lang !== this.$root.$i18n.locale) {
          this.$root.$i18n.locale = this.user.profile.lang
          document.documentElement.lang = this.user.profile.lang
        }
        auth
          .getConfig({ $http: this.$http })
          .then(res => {
            if (res.status !== 200 || !res.data.success) throw new Error(res.response)
            this.MERGE_CONFIG(Object.assign(res.data.config, {merged: true}))
          })
          .catch(err => {
            console.warn('app error in request for config', err)
          })
        unwatch()
      } else {
        if (!this.$route.requiresAuth && !this.$route.meta.public) {
          this.$router.push('/login')
        }
      }
    })
  },
  components: { MainHeader, MainFooter },
  data () {
    return {
      loaded: false
    }
  },
  computed: {
    ...mapState({
      user: ({ App }) => App.user
    })
  },
  methods: {
    ...mapActions(['getHomeConfig']),
    ...mapMutations(['MERGE_CONFIG'])
  }
}
</script>

<style type="text/css">
input[type='number'] {
  -moz-appearance: textfield;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
