import _ from 'lodash/fp'
import * as types from '@/store/mutation-types'

const actions = {
  //
  // PRODUCTS
  //
  getProduct ({ commit }, payload) {
    let id = payload.id
    const getBy = payload.getBy || '_id'
    const detail = payload.detail || false

    let opts = `?populate=true&detail=true&getBy=${getBy}` //{ populate: true, other: { detail: detail } }
    let _id = id
    if (getBy !== '_id') {
      // opts.other.getBy = getBy
      const keys = _id.split('/')
      id = keys[0]
      // if (keys.length > 1) opts.other.variationKey = keys[1]
      // _id = keys[0]
      opts += `&variationKey=${keys[1]}`
    }
    return this.$http
      .get(`/products/${id}` + opts)
      .then(data => {
        if (detail) {
          commit(types.GOT_PRODUCT_DETAIL, data.data)
          return data.data
        } else {
          commit(types.GOT_PRODUCT, data)
          return data.data
        }
      })
      .catch(err => {
        console.log('Err: ', err)
        commit(types.REQ_ERROR, err)
      })
  },

  getProducts ({ commit }, collection) {
    return this.$http
      .get('/products?populate=true' + (collection ? collection : ''))
      .then(data => {
        commit(types.GOT_PRODUCTS, data)
        return data
      })
      .catch(err => {
        console.log('Err: ', err)
        commit(types.REQ_ERROR, err)
      })
  },

  getCollectionProducts ({ commit }, payload) {
    const id = payload.id

    return this.$http
      .get(`/collections/${id}/products?key=${payload.key}&populate=true`)
      .then(res => {
        if (typeof res.data === 'string') {
          commit(types.SHOW_COLLECTION_PRODUCTS, id)
        } else {
          if (_.isArray(res.data.data)) {
            commit(types.GOT_COLLECTION_LIST, res.data.data)
          } else {
            commit(types.GOT_COLLECTION_LIST, res.data.data)
            // commit(types.GOT_COLLECTION_PRODUCTS, res.data.data)
          }
        }
        return res.data.data
      })
      .catch(err => {
        console.log('Err: ', err)
        commit(types.REQ_ERROR, err)
      })
  },

  getProgramProducts ({ commit }, payload) {
    const id = payload.id

    this.$http
      .get(
        `/programs/${id}/products?by=program&key=${payload.key}&list=${payload.list}&populate=true`
      )
      .then(data => {
        if (typeof data === 'string') {
          commit(types.SHOW_PROGRAM_PRODUCTS, id)
        } else {
          if (payload.list) {
            commit(types.GOT_COLLECTION_LIST, data.data)
          } else {
            commit(types.GOT_COLLECTION_PRODUCTS, data.data)
          }
        }
        return data
      })
      .catch(err => {
        console.log('Err: ', err)
        commit(types.REQ_ERROR, err)
      })
  },

  getPreorderProducts ({ commit, rootState }, cartid) {
    this.$http
      .get(
        `/users/preorders?type=preorder&cartid=${cartid}&userid=${rootState.App.user._id}&populate=true`
        // `/orders?type=preorder&cartid=${cartid}&userid=${rootState.App.user.id}&populate=true`
      )
      .then(data => {
        commit(types.GOT_PREORDER_PRODUCTS, data.data.data)
        return data
      })
      .catch(err => {
        console.log('Err: ', err)
        commit(types.REQ_ERROR, err)
      })
  },

  getCustomorderProducts ({ commit, rootState }, cartid) {
    console.info(' :: get, rootstate', rootState)
    this.$http
      .get(
        `/users/customorders?type=custom&cartid=${cartid}&userid=${rootState.App.user._id}&populate=true`
        // `/orders?type=custom&cartid=${cartid}&userid=${rootState.App.user.id}&populate=true`
      )
      .then(data => {
        commit(types.GOT_CUSTOMORDER_PRODUCTS, data)
        return data
      })
      .catch(err => {
        console.log('Err: ', err)
        commit(types.REQ_ERROR, err)
      })
  },

  getCartProducts ({ commit, rootState }, cartid) {
    return this.$http
      // .get(`/products?cartid=${cartid}&populate=true`)
      .get(`/users/cart?cartid=${cartid}&populate=true`)
      .then(res => {
        if (!res.data.success) {
          if (res.data.err !== 'no items in cart') commit(types.REQ_ERROR, res.data.err)
          return res
        } else {
          commit(types.GOT_CART, { cart: res.data.data, type: cartid, root: rootState.App }) //, state)
          return res.data
        }
      })
      .catch(err => {
        console.log('Err: ', err)
        commit(types.REQ_ERROR, err)
        return err
      })
  },

  filterCollectionProducts ({ commit }, params) {
    commit(types.FILTER_COLLECTION_PRODUCTS, params)
  },

  getProductFamily ({ commit }, _keys) {
    commit(types.SHOW_PRODUCT_FAMILY, _keys)
  }
}

export default actions
