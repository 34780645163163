<!-- src/components/Profile.vue -->

<template src="@/../../../templates/brand/components/templates/HomeUser.html"> </template>

<script>
import _ from 'lodash/fp'
import { mapActions, mapState, mapGetters } from 'vuex'
import countDown from '@/components/Countdown.vue'
import Carousel from '@/components/pCarousel.vue'
import acl from '@/../../../templates/site/UI/app/acl-rules.js'
import Kramed from 'kramed'

export default {
  components: { countDown, Carousel },
  data () {
    return {
      locales: _.keys(this.$store.state.lang),
      loaded: false,
      skipProgramsPage: true,
      kramed: Kramed
    }
  },
  created () {
    this.loaded = false
    // this.getHomeConfig()
    this.getCollections().then(() => {
      this.getPrograms().then(() => {
        this.loaded = true
      })
    })
  },
  methods: {
    ...mapActions([
      'getCollections',
      'getPrograms',
      'setCollection',
      'profileUpdate'
    ]),
    showFlash (flashes, flash) {
      if (flashes && flashes[flash]) {
        return flashes[flash][this.$i18n.locale]
      }
    },
    resolveRoute (dest) {
      const coll = this.collections.find(c => c._id === dest.collection)
      if (!coll) return
      if (dest.agreementRequired) {
        if (coll.type === 'program') {
          this.programAgreement(coll)
        } else if (coll.type === 'custom') {
          this.customAgreement(coll)
        }
      } else {
        if (coll.type !== 'program' && ~dest.url.indexOf('category/')) {
          const cat = dest.url.slice(dest.url.indexOf('category/') + 9)
          console.info(' ::: carousel category click.')
          this.$router.push({name: 'collectionCategoryProducts', params: {cat1: cat, collection: coll.url}})
        } else {
          this.$router.push(dest.url) //go(this.collectionLink(coll))
        }
      }
    },
    collectionLink (coll) {
      // regular product collections
      if (~['product', 'custom'].indexOf(coll.type))
        return { path: `/shop/${coll.url}`, params: { collection: coll.url } }
      // program collections
      if (coll.type === 'program') {
        if (this.noProgram) return ''
        if (
          this.programs.length > 1 ||
          (this.programs.length === 1 && _.isEmpty(this.current))
        ) {
          if (!this.skipProgramsPage) {
            return { path: `/programs`, params: { collection: coll.url } }
          } else {
            return ''
          }
        } else if (!_.isEmpty(this.current)) {
          return {
            path: `/programs/${this.current.url}`,
            params: { collection: coll.url }
          }
        } else {
          return { path: `/programs`, params: { collection: coll.url } }
        }
      }
      // those 'other' collections
      if (coll.type === 'other') {
        return { path: `/shop/${coll.url}`, params: { collection: coll.url } }
      }
    },
    programAgreement (coll) {
      if (this.noProgram) return
      let ctx = this
      this.$vex.dialog.open({
        contentClassName: 'agree-dialog',
        unsafeMessage: `
						<div>
							<h3>${this.$t('modal.program.title')}</h3>
							<p>${this.appconfig.programAgreement[this.$i18n.locale]}</p>
						</div>
						<div align="center">
							<p id="errmsg" style="visibility: hidden; text-align: center; color: red;" class="warning">You must accept the terms to enter</p>
						</div>
					`,
        input: `<div align="center">
								<p><input name="agree" value="1" type="checkbox" ${
                  this.user.profile.extradata.termsAccepted ? 'checked' : ''
                }>
								<label for="agree">${this.$t('modal.program.disclaimer')}</label></p>
							</div>
					`,
        beforeClose () {
          if (this.value && _.isEmpty(this.value)) {
            window.$(this.contentEl)
              .find('#errmsg')
              .css('visibility', 'visible')
            return false
          } else if (this.value.agree) {
            const _profile = _.assign({}, ctx.user.profile)
            _profile.extradata.termsAccepted = true
            ctx.profileUpdate({ user: ctx.user, profile: _profile })
            ctx.$router.push(ctx.collectionLink(coll))
          }
        }
      })
    },
    customAgreement (coll) {
      let ctx = this
      this.$vex.dialog.buttons.YES.text = this.$t('modal.custom.continue')
      console.info(' ::: btns', this.$vex.dialog.buttons)
      this.$vex.dialog.open({
        contentClassName: 'agree-dialog',
        unsafeMessage: `
						<div>
							<h3>${this.$t('modal.custom.title')}</h3>
							<p>${this.appconfig.customAgreement[this.$i18n.locale]}</p>
						</div>
						<div align="center">
							<p id="errmsg" style="visibility: hidden; text-align: center; color: red;" class="warning">You must accept the terms to enter</p>
						</div>
					`,
        input: `<div align="center">
								<p style="text-align: center;"><input name="agree" value="1" type="checkbox" ${
                  this.user.profile.extradata.customTermsAccepted
                    ? 'checked'
                    : ''
                }>
								<label for="agree">${this.$t('modal.custom.disclaimer')}</label></p>
							</div>
					`,
        beforeClose () {
          if (this.value && _.isEmpty(this.value)) {
            window.$(this.contentEl)
              .find('#errmsg')
              .css('visibility', 'visible')
            return false
          } else if (this.value.agree) {
            const _profile = _.assign({}, ctx.user.profile)
            _profile.extradata.customTermsAccepted = true
            ctx.profileUpdate({ user: ctx.user, profile: _profile })
            ctx.$router.push(ctx.collectionLink(coll))
          }
        }
      })
    }
  },
  computed: {
    ...mapState({
      homeConfig: ({ Appui }) => Appui.homeConfig,
      collections: ({ Collection, App }) => {
        const _filtered = Collection.collections.filter(
          c => !acl[c.url] || acl[c.url](App.user.role.level)
        )
        return _.sortBy(item => item.order, _filtered)
      },
      programs: ({ Collection }) => Collection.programs,
      byStarts: ({ Collection }) =>
        _.sortBy(i => new Date(i.starts), Collection.programs),
      byEnds: ({ Collection }) =>
        _.sortBy(i => new Date(i.ends), Collection.programs),
      current: ({ Collection }) => {
        if (!Collection.programs) return {}
        const _now = new Date()
        return (
          _.find(
            i => new Date(i.starts) < _now && new Date(i.ends) > _now,
            Collection.programs
          ) || {}
        )
      },
      user: ({ App }) => App.user
    }),
    ...mapGetters({
      appconfig: 'const'
    }),
    carousels () {
      if (!this.homeConfig || !this.homeConfig.length) return { main: {} }
      return this.homeConfig.reduce((acc, i) => {
        if (i.type === 'Carousel') acc[i.title] = JSON.parse(i.json)
        return acc
      }, {})
    },
    noProgram () {
      if (_.isEmpty(this.programs) || !this.programs) return true //! this.programs.length;
      if (
        !_.find(
          p => new Date(p.ends) > new Date() && new Date(p.starts) < new Date(),
          this.programs
        )
      )
        return true
      return false
    },
    nextStarts () {
      let _next = { starts: null }
      if (this.byStarts.length) {
        if (
          _.find(
            i => i.active && new Date(i.starts) > new Date(),
            this.byStarts
          )
        ) {
          _next = _.find(
            i => i.active && new Date(i.starts) > new Date(),
            this.byStarts
          )
        }
      }
      return _next
    },
    nextEnds () {
      const _p = this.byEnds[0]
      if (!_.isEmpty(this.current) && _p._id === this.current._id) return {}
      return _p || {}
    },
    showNext () {
      return !this.nextStarts.starts
    }
  }
}
</script>

<style src="@/../../../templates/brand/components/styles/HomeUser.css"></style>
